.wrapper {
    width: 1240px;
    position: relative;
    margin: 0;
    background-color: var(--color_lightGray3);
    color: var(--color_darkGray);
    padding: 60px 180px;
    border-radius: 10px;
    font-size: var(--fontSize_readable);
    margin-bottom: 40px;
    &.small {
       padding: 40px;
    }
}
.title {
    font-size: var(--fontSize_h1);
    color: var(--color_black);
    margin-top: 10px;
    font-weight: bold;
}
.horisontal {
    display: flex;
    margin-top: 40px;
    justify-content: space-between;
}
.ndaa {
   opacity: 0.334 !important;
}    
.tile {
    width: 400px;
    height: 175px;
    padding: 40px;
    opacity: 0.9;
    border-radius: 10px;
    border: dashed 1px var(--color_lightGray2);
    background-color: var(--color_white);
    transition: opacity 0s 0.2s; 
    &.newProject {
      height: 390px;  
    }
    &.project {
       border: dashed 1px var(--color_primary);
       &.semi {
          position: relative;
          border: dashed 1px var(--color_primary_semi);
        & .subTitle, & .subText {
            opacity: 0.334;
        }
        &:hover .subTitle, &:hover .subText {
            opacity: 0.3;
        }
        &:hover .hint {
                opacity: 1;
                visibility: visible;
        }  
       }
    }
    &.small {
       width: 300px; 
    }
    &.help {
        width: 140px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        position: relative;
    }
    &.opaque {
       border-color: var(--color_lightGray3);
       background-color: var(--color_lightGray3);
    }    
    & .uploadDoc {
      margin: 16px auto;
      color: var(--color_gray);
      width: fit-content;
      font-size: var(--fontSize_18);
    }
}
.tile:hover {
    opacity: 1;
}
.subTitle {
    color: var(--color_black);
    font-weight: bold;
    font-size: var(--fontSize_h2);    
    &.withMargin {
       margin-bottom: 20px; 
    }
}
.clickable {
    cursor: pointer;
}
.getstarted {
  font-size: var(--fontSize_button);
  font-weight: bold;
  line-height: normal;
  color: #fff;
  width: 83px;
  margin: 0px 0 0px 40px;
  padding: 2px 10px;
  border-radius: 5px;
  border: solid 1px var(--color_primary);
  background-color: var(--color_primary);
}
.arrowShift {
    top: 3px;
}
.hint {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    left: 35px;
    top: -35px;
    width: 220px;
    color: var(--color_lightGray2);
    font-size: var(--fontSize_tip);
    border-radius: 4px;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.15);
    background-color: var(--color_darkGray);
    z-index: 1;
    padding: 10px;
    transition: all .2s;
    &:before {
       border: solid transparent;
        position: absolute;
        border-width: 9px;
        content: '';
        top: 100%;
        border-top-color: var(--color_darkGray);
        border-bottom: none; 
    }
    & > div {
        display: inline-block;
        cursor: pointer;
        transition: color .2s;
        &:hover {
            color: var(--color_primary_hover);
        }
    }
    }